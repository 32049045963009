import {defer, type LoaderArgs} from '@shopify/remix-oxygen';
import {Suspense} from 'react';
import {Await, useLoaderData, useRouteLoaderData} from '@remix-run/react';
import {
  ProductSwimlane,
  FeaturedCollections,
  Hero,
  ProductGallery,
  Section,
  Heading,
  Text,
  AddToCartButton,
  ProductCard,
} from '~/components';
import {MEDIA_FRAGMENT, PRODUCT_CARD_FRAGMENT} from '~/data/fragments';
import {getHeroPlaceholder} from '~/lib/placeholders';
import type {
  CollectionConnection,
  MediaImage,
  Metafield,
  ProductConnection,
} from '@shopify/hydrogen/storefront-api-types';
import {AnalyticsPageType, Money} from '@shopify/hydrogen';
import {
  ProductDetail,
  ProductForm,
  PRODUCT_VARIANT_FRAGMENT,
} from './products/$productHandle';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper';
import {domainToHandle} from '~/lib/const';
import {useState} from 'react';

interface HomeSeoData {
  shop: {
    name: string;
    description: string;
  };
}

export interface CollectionHero {
  byline: Metafield;
  cta: Metafield;
  handle: string;
  heading: Metafield;
  height?: 'full';
  loading?: 'eager' | 'lazy';
  spread: Metafield;
  spreadSecondary: Metafield;
  top?: boolean;
}

export async function loader({params, context, request}: LoaderArgs) {
  const {language, country} = context.storefront.i18n;

  if (
    params.lang &&
    params.lang.toLowerCase() !== `${language}-${country}`.toLowerCase()
  ) {
    // If the lang URL param is defined, yet we still are on `EN-US`
    // the the lang param must be invalid, send to the 404 page
    throw new Response(null, {status: 404});
  }

  const url = new URL(request.url);
  const previewHandle = url.searchParams.get('preview');

  const {shop, hero} = await context.storefront.query<{
    hero: CollectionHero;
    shop: HomeSeoData;
  }>(HOMEPAGE_SEO_QUERY, {
    variables: {handle: 'freestyle'},
  });

  const onepage = await context.storefront.query<any>(ONEPAGE_SEO_QUERY, {
    variables: {
      country,
      language,
      handle: previewHandle ?? domainToHandle(url.host) ?? 'scorpions',
    },
  });

  return defer({
    shop,
    primaryHero: hero,
    // These different queries are separated to illustrate how 3rd party content
    // fetching can be optimized for both above and below the fold.
    featuredProducts: context.storefront.query<{
      products: ProductConnection;
    }>(HOMEPAGE_FEATURED_PRODUCTS_QUERY, {
      variables: {
        /**
         * Country and language properties are automatically injected
         * into all queries. Passing them is unnecessary unless you
         * want to override them from the following default:
         */
        country,
        language,
      },
    }),
    secondaryHero: context.storefront.query<{hero: CollectionHero}>(
      COLLECTION_HERO_QUERY,
      {
        variables: {
          handle: 'backcountry',
          country,
          language,
        },
      },
    ),
    onepage,
    featuredCollections: context.storefront.query<{
      collections: CollectionConnection;
    }>(FEATURED_COLLECTIONS_QUERY, {
      variables: {
        country,
        language,
      },
    }),
    tertiaryHero: context.storefront.query<{hero: CollectionHero}>(
      COLLECTION_HERO_QUERY,
      {
        variables: {
          handle: 'winter-2022',
          country,
          language,
        },
      },
    ),
    analytics: {
      pageType: AnalyticsPageType.home,
    },
  });
}

export default function Homepage() {
  const {
    primaryHero,
    secondaryHero,
    tertiaryHero,
    featuredCollections,
    featuredProducts,
    onepage,
  } = useLoaderData<typeof loader>();
  const data = useRouteLoaderData('root') as any;

  // TODO: skeletons vs placeholders
  const skeletons = getHeroPlaceholder([{}, {}, {}]);

  // TODO: analytics
  // useServerAnalytics({
  //   shopify: {
  //     pageType: ShopifyAnalyticsConstants.pageType.home,
  //   },
  // });

  const onepageFeaturedProducts =
    onepage?.metaobject?.products?.references?.nodes;

  const onepageEntries =
    data?.onepage?.metaobject?.fields?.map(
      (field: {key: string; value?: string}) => {
        return [field.key, field.value];
      },
    ) ?? [];

  const onepageObject = {
    ...Object.fromEntries(onepageEntries),
    ...data?.onepage?.metaobject,
  };

  return (
    <>
      {onepageObject?.hero?.reference && (
        <BMGHero {...onepageObject?.hero?.reference} />
      )}
      <div>
        <div className="max-w-5xl m-auto my-16 px-6">
          <h2 className="font-bold my-2">{onepageObject?.mini_header ?? ''}</h2>
          <p
            className="whitespace-pre-wrap"
            dangerouslySetInnerHTML={{__html: onepageObject?.mini_text ?? ''}}
          ></p>
        </div>
      </div>
      <div id="products"></div>
      <div className="max-w-5xl m-auto">
        {onepageFeaturedProducts &&
          onepageObject?.show_big_product_grid != 'true' &&
          onepageFeaturedProducts.map((product: any, idx: number) => (
            <FeaturedProduct
              key={product.id}
              product={product}
              reverse={idx % 2 === 1}
            />
          ))}
      </div>
      {onepageObject?.show_big_product_grid == 'true' && (
        <BMGProductGrid products={onepageFeaturedProducts} />
      )}
      {onepageObject?.show_product_grid == 'true' && (
        <ProductSwimlane
          products={onepageFeaturedProducts}
          title="Produkte"
          count={4}
        />
      )}
    </>
  );
}

function BMGProductGrid({products}) {
  return (
    <>
      {products && products.length > 0 && (
        <div className="max-w-5xl m-auto my-16 px-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {products.map((product: any, idx: number) => (
              <ProductCard
                product={product}
                key={product.id}
                className="w-80"
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

interface BMGHeroProps {
  title?: Metafield | null;
  text?: Metafield | null;
  image?: Metafield | null;
  buttonText?: Metafield | null;
}

function BMGHero({title, text, image, buttonText}: BMGHeroProps) {
  const hasImage = Boolean(image?.reference);
  return (
    <div className="relative">
      {image?.reference && (
        <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
          <img
            src={(image?.reference as MediaImage)?.image?.url ?? ''}
            alt=""
            className="h-full w-full object-cover object-center"
          />
        </div>
      )}
      <div
        className={`relative mx-auto flex max-w-3xl flex-col items-center px-6 text-center ${
          hasImage ? 'py-48 sm:py-64' : 'py-16 sm:py-24'
        } lg:px-0`}
      >
        <h1
          className={`text-4xl font-bold tracking-tight ${
            hasImage && 'text-white'
          } lg:text-6xl`}
        >
          {title?.value != '.' ? title?.value ?? '' : ''}
        </h1>
        <p className={`mt-4 text-xl ${hasImage && 'text-white'}`}>
          {text?.value ?? ''}
        </p>
        {buttonText?.value && (
          <a
            href="#products"
            className="mt-8 inline-block rounded border border-transparent bg-primary py-3 px-8 text-base font-medium text-white hover:bg-primary/80"
          >
            {buttonText?.value}
          </a>
        )}
      </div>
    </div>
  );
}

function FeaturedProduct({product, reverse}: any) {
  const {media, title, vendor, descriptionHtml} = product;
  const firstVariant = product.variants.nodes[0];
  const [variant, setVariant] = useState(firstVariant.id);

  const _gallery = (
    <ProductGallery
      media={media.nodes.slice(0, 3)}
      className="w-screen md:w-full lg:col-span-1"
    />
  );

  const gallery = (
    <div className="w-screen md:w-full lg:col-span-1 max-h-[600px] relative">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Pagination]}
        color={'#000'}
        pagination={{enabled: true, clickable: true}}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {media.nodes.map((data: any) => (
          <SwiperSlide key={data.id}>
            <img
              src={data.image!.url}
              alt={data.image!.altText!}
              className="w-full h-full aspect-square fadeIn object-cover"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );

  const handleSelectChange = (e) => {
    setVariant(e.target.value);
  };

  const text = (
    <div
      className={`${
        reverse ? 'lg:order-first' : ''
      } md:-mb-nav md:top-nav md:-translate-y-nav md:pt-nav hiddenScroll md:overflow-y-scroll`}
    >
      <section className="flex flex-col w-full max-w-xl lg:max-w-full gap-4 p-6 md:mx-auto md:max-w-sm md:px-0">
        <div className="grid gap-2">
          <Heading as="h1" className="whitespace-normal">
            {title}
          </Heading>
          {false && vendor && (
            <Text className={'opacity-50 font-medium'}>{vendor}</Text>
          )}
        </div>
        <div>
          <Money className="text-lg" data={firstVariant.price} />
          inkl. MwSt. zzgl. Versandkosten
        </div>
        <div>
          <select
            value={variant}
            onChange={handleSelectChange}
            className={`${
              product.variants.nodes.length > 1 ? 'max-w-[80vw]' : 'hidden'
            }`}
          >
            {product.variants.nodes.map((variant: any) => (
              <option key={variant.id} value={variant.id}>
                {variant.title}
              </option>
            ))}
          </select>
        </div>
        <AddToCartButton
          lines={[
            {
              quantity: 1,
              merchandiseId: variant,
            },
          ]}
          variant="secondary"
          className="mt-2"
        >
          <Text as="span" className="flex items-center justify-center gap-2">
            {product.variants.nodes.find((v) => v.id == variant)
              ?.availableForSale
              ? 'Zum Warenkorb'
              : 'Ausverkauft'}
          </Text>
        </AddToCartButton>
        <div className="grid gap-4 py-4">
          {descriptionHtml && (
            <ProductDetail
              title="Beschreibung"
              defaultOpen
              content={descriptionHtml}
            />
          )}
        </div>
      </section>
    </div>
  );

  return (
    <>
      <Section padding="x" className="!px-0">
        <div className="grid items-start md:gap-6 lg:gap-8 md:grid-cols-2 lg:grid-cols-2 mt-8">
          {gallery}
          {text}
        </div>
      </Section>
      <div className="relative w-full min-h-[120px]"></div>
    </>
  );
}

const COLLECTION_CONTENT_FRAGMENT = `#graphql
  ${MEDIA_FRAGMENT}
  fragment CollectionContent on Collection {
    id
    handle
    title
    descriptionHtml
    heading: metafield(namespace: "hero", key: "title") {
      value
    }
    byline: metafield(namespace: "hero", key: "byline") {
      value
    }
    cta: metafield(namespace: "hero", key: "cta") {
      value
    }
    spread: metafield(namespace: "hero", key: "spread") {
      reference {
        ...Media
      }
    }
    spreadSecondary: metafield(namespace: "hero", key: "spread_secondary") {
      reference {
        ...Media
      }
    }
  }
`;

const HOMEPAGE_SEO_QUERY = `#graphql
  ${COLLECTION_CONTENT_FRAGMENT}
  query collectionContent($handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    hero: collection(handle: $handle) {
      ...CollectionContent
    }
    shop {
      name
      description
    }
  }
`;

const ONEPAGE_SEO_QUERY = `#graphql
  ${PRODUCT_CARD_FRAGMENT}
  ${MEDIA_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}
  query onepageContent($country: CountryCode, $language: LanguageCode, $handle: String!)
  @inContext(country: $country, language: $language) {
    metaobject(handle: { handle: $handle, type: "one_page_store"}) {
      menus: field(key: "footer_menus") {
        references(first: 20) {
          nodes {
            ... on Metaobject {
              id
              title: field(key: "title") {
                value
              }
              links: field(key: "links") {
                references(first: 20) {
                  nodes {
                    ... on Metaobject {
                      id
                      label: field(key: "label") {
                        value
                      }
                      url: field(key: "url") {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      footerImage: field(key: "footer_image") {
        reference {
          ... on MediaImage {
            id
            image {
              altText
              url
            }
          }
        }
      }
      products: field(key: "products") {
        references(first: 20) {
          nodes {
            ...ProductCard
            ... on Product {
              id
              title
              vendor
              handle
              descriptionHtml
              description
              options {
                name
                values
              }
              media(first: 7) {
                nodes {
                  ...Media
                }
              }
              variants(first: 40) {
                nodes {
                  ...ProductVariantFragment
                }
              }
              seo {
                description
                title
              }
            }
          }
        }
      }
      fields {
        key
        value
        type
      }
    }
  }
`;

const COLLECTION_HERO_QUERY = `#graphql
  ${COLLECTION_CONTENT_FRAGMENT}
  query collectionContent2($handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    hero: collection(handle: $handle) {
      ...CollectionContent
    }
  }
`;

// @see: https://shopify.dev/api/storefront/latest/queries/products
export const HOMEPAGE_FEATURED_PRODUCTS_QUERY = `#graphql
  ${PRODUCT_CARD_FRAGMENT}
  query homepageFeaturedProducts($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    products(first: 8) {
      nodes {
        ...ProductCard
      }
    }
  }
`;

// @see: https://shopify.dev/api/storefront/latest/queries/collections
export const FEATURED_COLLECTIONS_QUERY = `#graphql
  query homepageFeaturedCollections($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    collections(
      first: 4,
      sortKey: UPDATED_AT
    ) {
      nodes {
        id
        title
        handle
        image {
          altText
          width
          height
          url
        }
      }
    }
  }
`;
